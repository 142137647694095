<script setup lang="ts">
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { vOnClickOutside } from '@vueuse/components';

import { OrganizationRights } from '@/client';

import { hasOrganizationRights, isSuperAdmin } from '@/composables/Auth';

import OTabPanels from '@/components/organisms/OTabPanels.vue';

import { profileModalIds } from '@/utils/DataTestIds.ts';

import {
  keyOutlined,
  accountTreeOutlined,
} from '@/assets/icons/materialSymbols/MaterialSymbols.ts';

defineProps({
  left: {
    type: String,
    default: '0',
  },
});

const emit = defineEmits(['hide']);
const activeTab = ref('settings');
const router = useRouter();

function hide() {
  emit('hide');
}

function changeTab(value: string) {
  activeTab.value = value;
  localStorage.setItem('scope-active-tab', value);
}

const settingsItems = [
  {
    id: 0,
    component: keyOutlined,
    text: 'API Tokens',
    to: 'tokens',
    visible: true,
  },
  {
    id: 1,
    component: accountTreeOutlined,
    text: 'Organizations Overview',
    to: 'metrics',
    visible: isSuperAdmin() || hasOrganizationRights(OrganizationRights.Internalmetricsread),
  },
];

function toPage(to: string) {
  router.push({
    name: to,
  });
}
</script>

<template>
  <q-card
    v-on-click-outside="hide"
    class="dialog__container"
    :class="$q.screen.lt.sm ? 'mobile-profile' : ''"
    :style="{ left: left }"
  >
    <q-card-section>
      <slot name="top" />
    </q-card-section>
    <OTabPanels
      :active-tab="activeTab"
      :tab-size="{ md: 12, lg: 12 }"
      default-tab="settings"
      class="gt-xs"
      @change-tab="changeTab"
    >
      <template #panels>
        <q-tab-panel name="settings">
          <div
            v-for="item in settingsItems"
            :key="item.id"
            :class="[!item.to ? 'disabled' : '']"
            class="item-wrapp"
            @click="item.to ? toPage(item.to) : null"
          >
            <div v-if="item.visible" class="row items-center panel-item">
              <q-icon
                :name="item.component"
                class="q-mr-md"
                size="20px"
                color="grey"
                :data-test-id="profileModalIds.profileModalTokenIcon"
              />
              <span>{{ item.text }}</span>
            </div>
          </div>
        </q-tab-panel>
      </template>
    </OTabPanels>
  </q-card>
</template>

<style lang="scss" scoped>
.dialog__container {
  min-width: 300px;
  position: fixed;
  bottom: 20px;
  z-index: 9999;
  :deep(.user) {
    span {
      @include paragraph-01(400, $secondary-800);
      margin-left: 1px;
    }
    .q-avatar {
      background: $secondary-100;
      span {
        @include paragraph-02(400, $secondary-800);
      }
    }
  }
  :deep(.user-info) {
    .user-name {
      @include paragraph-01(400, $common-4);
    }
    .user-email {
      @include caption(400, rgba(0, 0, 0, 0.45));
    }
  }
  :deep(.q-tab) {
    padding: 0;
    .q-tab__content {
      justify-content: flex-end;
      flex-direction: row-reverse !important;
      .q-tab__label {
        margin-left: 35px;
      }
    }
    svg {
      position: absolute;
      left: 20px;
      line-height: 0;
    }
    .q-tab__indicator {
      background: $secondary-100;
    }
  }
  :deep(.q-tab-panels) {
    margin: 0;
    .q-tab-panel {
      padding: 0;
      border-top: 1px solid $secondary-100;
      .panel-item {
        padding: 15px 20px;
        span {
          @include paragraph-01(400, $common-4);
        }
      }
      .item-wrapp:not(:last-child) {
        border-bottom: 1px solid #f0f0f0;
      }
      .panel-item:hover,
      i:hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }
  }
  .disabled {
    opacity: 0.7;
  }
}

.mobile-profile {
  position: fixed;
  bottom: 75px;
  z-index: 9999;
}
</style>
