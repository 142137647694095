/* tslint:disable */
/* eslint-disable */
/**
 * Modulos Platform API
 * API for the Modulos Platform.
 *
 * The version of the OpenAPI document: 1.1.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { ErrorResponse } from '../model';
// @ts-ignore
import type { HTTPValidationError } from '../model';
// @ts-ignore
import type { OrganizationConfig } from '../model';
// @ts-ignore
import type { PatchOrganizationConfig } from '../model';
// @ts-ignore
import type { UpdateOrganizationConfig } from '../model';
/**
 * ConfigApi - axios parameter creator
 * @export
 */
export const ConfigApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Organization Config
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationConfigApiV1ConfigOrganizationsOrganizationIdGet: async (organizationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getOrganizationConfigApiV1ConfigOrganizationsOrganizationIdGet', 'organizationId', organizationId)
            const localVarPath = `/api/v1/config/organizations/{organization_id}`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch Organization Config
         * @param {string} organizationId 
         * @param {PatchOrganizationConfig} patchOrganizationConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPatch: async (organizationId: string, patchOrganizationConfig: PatchOrganizationConfig, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('patchOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPatch', 'organizationId', organizationId)
            // verify required parameter 'patchOrganizationConfig' is not null or undefined
            assertParamExists('patchOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPatch', 'patchOrganizationConfig', patchOrganizationConfig)
            const localVarPath = `/api/v1/config/organizations/{organization_id}`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchOrganizationConfig, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Organization Config
         * @param {string} organizationId 
         * @param {UpdateOrganizationConfig} updateOrganizationConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPut: async (organizationId: string, updateOrganizationConfig: UpdateOrganizationConfig, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('updateOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPut', 'organizationId', organizationId)
            // verify required parameter 'updateOrganizationConfig' is not null or undefined
            assertParamExists('updateOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPut', 'updateOrganizationConfig', updateOrganizationConfig)
            const localVarPath = `/api/v1/config/organizations/{organization_id}`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOrganizationConfig, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConfigApi - functional programming interface
 * @export
 */
export const ConfigApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConfigApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Organization Config
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationConfigApiV1ConfigOrganizationsOrganizationIdGet(organizationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationConfigApiV1ConfigOrganizationsOrganizationIdGet(organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfigApi.getOrganizationConfigApiV1ConfigOrganizationsOrganizationIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Patch Organization Config
         * @param {string} organizationId 
         * @param {PatchOrganizationConfig} patchOrganizationConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPatch(organizationId: string, patchOrganizationConfig: PatchOrganizationConfig, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPatch(organizationId, patchOrganizationConfig, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfigApi.patchOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Organization Config
         * @param {string} organizationId 
         * @param {UpdateOrganizationConfig} updateOrganizationConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPut(organizationId: string, updateOrganizationConfig: UpdateOrganizationConfig, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPut(organizationId, updateOrganizationConfig, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfigApi.updateOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ConfigApi - factory interface
 * @export
 */
export const ConfigApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConfigApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Organization Config
         * @param {ConfigApiGetOrganizationConfigApiV1ConfigOrganizationsOrganizationIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationConfigApiV1ConfigOrganizationsOrganizationIdGet(requestParameters: ConfigApiGetOrganizationConfigApiV1ConfigOrganizationsOrganizationIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<OrganizationConfig> {
            return localVarFp.getOrganizationConfigApiV1ConfigOrganizationsOrganizationIdGet(requestParameters.organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch Organization Config
         * @param {ConfigApiPatchOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPatch(requestParameters: ConfigApiPatchOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<OrganizationConfig> {
            return localVarFp.patchOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPatch(requestParameters.organizationId, requestParameters.patchOrganizationConfig, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Organization Config
         * @param {ConfigApiUpdateOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPut(requestParameters: ConfigApiUpdateOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<OrganizationConfig> {
            return localVarFp.updateOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPut(requestParameters.organizationId, requestParameters.updateOrganizationConfig, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getOrganizationConfigApiV1ConfigOrganizationsOrganizationIdGet operation in ConfigApi.
 * @export
 * @interface ConfigApiGetOrganizationConfigApiV1ConfigOrganizationsOrganizationIdGetRequest
 */
export interface ConfigApiGetOrganizationConfigApiV1ConfigOrganizationsOrganizationIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ConfigApiGetOrganizationConfigApiV1ConfigOrganizationsOrganizationIdGet
     */
    readonly organizationId: string
}

/**
 * Request parameters for patchOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPatch operation in ConfigApi.
 * @export
 * @interface ConfigApiPatchOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPatchRequest
 */
export interface ConfigApiPatchOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof ConfigApiPatchOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPatch
     */
    readonly organizationId: string

    /**
     * 
     * @type {PatchOrganizationConfig}
     * @memberof ConfigApiPatchOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPatch
     */
    readonly patchOrganizationConfig: PatchOrganizationConfig
}

/**
 * Request parameters for updateOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPut operation in ConfigApi.
 * @export
 * @interface ConfigApiUpdateOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPutRequest
 */
export interface ConfigApiUpdateOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof ConfigApiUpdateOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPut
     */
    readonly organizationId: string

    /**
     * 
     * @type {UpdateOrganizationConfig}
     * @memberof ConfigApiUpdateOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPut
     */
    readonly updateOrganizationConfig: UpdateOrganizationConfig
}

/**
 * ConfigApi - object-oriented interface
 * @export
 * @class ConfigApi
 * @extends {BaseAPI}
 */
export class ConfigApi extends BaseAPI {
    /**
     * 
     * @summary Get Organization Config
     * @param {ConfigApiGetOrganizationConfigApiV1ConfigOrganizationsOrganizationIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public getOrganizationConfigApiV1ConfigOrganizationsOrganizationIdGet(requestParameters: ConfigApiGetOrganizationConfigApiV1ConfigOrganizationsOrganizationIdGetRequest, options?: RawAxiosRequestConfig) {
        return ConfigApiFp(this.configuration).getOrganizationConfigApiV1ConfigOrganizationsOrganizationIdGet(requestParameters.organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch Organization Config
     * @param {ConfigApiPatchOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public patchOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPatch(requestParameters: ConfigApiPatchOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPatchRequest, options?: RawAxiosRequestConfig) {
        return ConfigApiFp(this.configuration).patchOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPatch(requestParameters.organizationId, requestParameters.patchOrganizationConfig, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Organization Config
     * @param {ConfigApiUpdateOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public updateOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPut(requestParameters: ConfigApiUpdateOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPutRequest, options?: RawAxiosRequestConfig) {
        return ConfigApiFp(this.configuration).updateOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPut(requestParameters.organizationId, requestParameters.updateOrganizationConfig, options).then((request) => request(this.axios, this.basePath));
    }
}

