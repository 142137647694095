<script setup lang="ts">
import { watch } from 'vue';
import { useRoute } from 'vue-router';

import { usePaginationStore } from '@/stores/PaginationStore.ts';
import { useProjectsStore } from '@/stores/ProjectsStore.ts';

import { Rights } from '@/client';
import { hasProjectRights, isSuperAdmin } from '@/composables/Auth.ts';

const route = useRoute();

const projectsStore = useProjectsStore();
const paginationStore = usePaginationStore();

function setActiveTab(tab: string) {
  if (!projectsStore.project) return;

  paginationStore.setFrameworkQuery(null);
  paginationStore.setStatusFilter(null);
  paginationStore.setTagFilter(null);
  paginationStore.setNameFilter('');
  paginationStore.setAssignedToMe(false);

  projectsStore.setActiveTab(tab);
}

watch(
  () => route.name,
  () => {
    paginationStore.setFrameworkQuery(null);
    paginationStore.setStatusFilter(null);
    paginationStore.setTagFilter(null);
    paginationStore.setNameFilter('');
    paginationStore.setAssignedToMe(false);
  },
);
</script>
<template>
  <div v-if="projectsStore.project" class="menu">
    <q-btn
      flat
      class="path"
      :to="`/projects/project-detail/${projectsStore.project.id}`"
      :class="route.name === 'project-dashboard' ? 'active' : ''"
      @click="setActiveTab('dashboard')"
    >
      Dashboard
    </q-btn>

    <q-btn
      flat
      class="path"
      :to="{
        name: 'project-requirements',
        params: route.params,
      }"
      :class="route.name === 'project-requirements' ? 'active' : ''"
      @click="setActiveTab('requirements')"
    >
      Requirements
    </q-btn>
    <q-btn
      flat
      class="path"
      :to="{
        name: 'project-controls',
        params: route.params,
        query: route.query,
      }"
      :class="route.name === 'project-controls' ? 'active' : ''"
      @click="setActiveTab('controls')"
    >
      Controls
    </q-btn>
    <q-btn
      flat
      class="path"
      :to="{
        name: 'project-risks',
        params: route.params,
        query: route.query,
      }"
      :class="route.name === 'project-risks' || route.name === 'risk-detail' ? 'active' : ''"
      @click="setActiveTab('risks')"
    >
      Risks
    </q-btn>
    <q-btn
      flat
      class="path"
      :to="{
        name: 'project-evidence',
        params: route.params,
        query: route.query,
      }"
      :class="route.name === 'project-evidence' ? 'active' : ''"
      @click="setActiveTab('evidence')"
    >
      Evidence
    </q-btn>
    <q-btn
      flat
      class="path"
      :to="{
        name: 'project-assets',
        params: route.params,
        query: route.query,
      }"
      :class="route.name === 'project-assets' || route.name === 'asset-detail' ? 'active' : ''"
      @click="setActiveTab('assets')"
    >
      Assets
    </q-btn>
    <q-btn
      flat
      class="path"
      :to="{
        name: 'project-testing',
        params: route.params,
        query: route.query,
      }"
      :class="route.name === 'project-testing' || route.name === 'test-detail' ? 'active' : ''"
      @click="setActiveTab('testing')"
    >
      Testing
      <q-badge class="preview-badge q-ml-xs">PREVIEW</q-badge>
    </q-btn>
    <q-btn
      v-if="
        isSuperAdmin() ||
        hasProjectRights(projectsStore.project.id, Rights.Update) ||
        hasProjectRights(projectsStore.project.id, Rights.Delete) ||
        hasProjectRights(projectsStore.project.id, Rights.UserRolesassign)
      "
      flat
      class="path"
      :to="{
        name: 'project-settings',
        params: route.params,
        query: route.query,
      }"
      :class="route.name === 'project-settings' ? 'active' : ''"
      @click="setActiveTab('settings')"
    >
      Settings
    </q-btn>
  </div>
</template>

<style scoped lang="scss">
.menu {
  a {
    @include paragraph-02(400, $secondary-500);
    text-transform: none;
    padding: 4px;
    margin: 0 16px;
  }
  .active {
    @include paragraph-02(700, $secondary-600);
    position: relative;
  }
  .active::after {
    content: '';
    width: 100%;
    height: 3px;
    background: $secondary-600;
    position: absolute;
    bottom: -15px;
    right: 0;
  }
}
</style>
